/**
 * This module is imported in the following files:
 *
 *   - /gatsby-browser.js
 *   - /gatsby-ssr.js
 *   - /.storybook/preview.js
 *
 * All exports are re-exported in the gatsby-* files allowing access to the
 * Gatsby Browser and SSR APIs.
 *
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 * @see https://www.gatsbyjs.org/docs/ssr-apis/
 */

import 'minireset.css'
import '@reach/skip-nav/styles.css'
import '@reach/dialog/styles.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@walltowall/siamese/dist/siamese.cjs.development.css'

import './index.css'

import * as React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { PreviewStoreProvider } from 'gatsby-source-prismic'
import { TreatProvider } from 'react-treat'

import { DebugProvider } from './hooks/useDebug'
import { ModalProvider } from './hooks/useModal'
import { theme } from './theme.treat'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <TreatProvider theme={theme}>
    <DebugProvider>
      <PreviewStoreProvider>
        <ModalProvider>{element}</ModalProvider>
      </PreviewStoreProvider>
    </DebugProvider>
  </TreatProvider>
)

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (!location.hash) return true

  const el = document.querySelector(location.hash)
  if (!el) return true

  return el.scrollIntoView()
}
