import React from 'react'
import GatsbyLink from 'gatsby-link'
import {
  Link as SystemLink,
  LinkProps as SystemLinkProps,
} from '@walltowall/siamese'
import { isInternal, extractAnchor, isAnchorOnly } from '@walltowall/helpers'

import { useModal } from '../hooks/useModal'
import { useSiteSettings } from '../hooks/useSiteSettings'

const extractHost = (url: string) => {
  try {
    const instance = new URL(url)
    return instance.host
  } catch {
    return undefined
  }
}

const GatsbyLinkShim = ({ href, ...props }: { href: string }) => (
  <GatsbyLink to={href} {...props} />
)

export type LinkProps = Omit<SystemLinkProps<'a'>, 'as'> & {
  skipExternalCheck?: boolean
}

export const Link = ({ skipExternalCheck = false, ...props }: LinkProps) => {
  const { openModal } = useModal()
  const { catchLinksWhitelist } = useSiteSettings()

  const openSpeedBump = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // Due to a Gatsby bug, we need to manually check if this link is an Anchor
    // tag and manually scroll the node into view if it exists. Until Gatsby
    // updates, we cannot resolve this issue and must rely on this.
    //
    // See: https://github.com/gatsbyjs/gatsby/issues/25778
    const isAnchor = isAnchorOnly(props.href)
    if (isAnchor) {
      const anchor = extractAnchor(props.href)
      const node = document.querySelector(anchor!) as HTMLElement
      if (!node) return

      node.style.scrollMarginTop = '7.5rem'
      node.scrollIntoView()
      return
    }

    if (
      skipExternalCheck ||
      isInternal(props.href) ||
      catchLinksWhitelist?.includes?.(extractHost(props.href))
    )
      return

    event.preventDefault()
    openModal('SPEEDBUMP', props.href)
  }

  return (
    <SystemLink
      routerLinkComponent={GatsbyLinkShim}
      onClick={openSpeedBump}
      {...props}
    />
  )
}
